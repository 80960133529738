@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Colors */
/* Fonts */
#LogContainer {
  display: flex;
  padding: 0em;
}

#LogContainer #userIcon {
  padding: 0.5em;
  height: 1.5em;
  width: 1.5em;
  border-radius: 100%;
  background-color: darkgrey;
}

#LogContainer #logTextContainer {
  padding: 0.5em;
  height: 1.5em;
  width: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#LogContainer #logTextContainer #currentUserContainer {
  font-size: 0.9em;
}

#LogContainer #logTextContainer #currentUserContainer #unlog {
  color: darkgrey;
  text-align: center;
}

@media (max-width: 560px) {
  #userIcon {
    padding: 0.15em;
    height: 1.15em;
    width: 1.15em;
  }
}
/*# sourceMappingURL=style.css.map */
@charset "UTF-8";
/* Colors */
/* Fonts */
/*Styles composant Bannière*/
#banner {
  display: flex;
  flex-direction: column;
  height: 6.5em;
}

#banner #bannerTop {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #B04541;
}

#banner #bannerTop #presentationPhoto a {
  position: absolute;
  top: -0.6em;
  left: -1.95em;
}

#banner #bannerTop #presentationPhoto a img {
  height: 100px;
  width: 100px;
  border: 5px solid #B04541;
  border-radius: 0 100% 100% 100%;
}

#banner #bannerTop h1 {
  flex: 2 1;
  margin: 0 0 0.25em 75px;
  padding: 0;
  font-size: 2.1em;
  letter-spacing: 0.05em;
}

#banner #bannerTop #bannerTopRight {
  flex: 8 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

#banner #bannerTop #bannerTopRight #navMenu {
  flex: 9 1;
  width: 100%;
}

#banner #bannerTop #bannerTopRight #navMenu ul {
  margin: 0 1em;
  display: flex;
  justify-content: flex-end;
}

#banner #bannerTop #bannerTopRight #navMenu ul li {
  margin: 0.4em;
  list-style: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.2em;
}

#banner #bannerTop #bannerTopRight #navMenu #logMenu {
  flex: 1 1;
}

#banner #bannerTop .bannerLink:hover {
  color: white;
}

#banner #bannerBottom {
  margin: 0 0 0 90px;
}

#banner #bannerBottom div {
  display: flex;
  align-items: flex-end;
}

#banner #bannerBottom div h2 {
  margin: 0;
  padding: 0;
  font-size: 1.3em;
}

#banner #bannerBottom div .presentationIcon {
  margin-left: 0.35em;
  color: #B04541;
  height: 1.4em;
  width: 1.4em;
}

#banner #bannerBottom span {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

#banner #cookieMessage {
  position: absolute;
  top: 0.5em;
  left: 50%;
  -webkit-transform: translateX(-12.5em);
          transform: translateX(-12.5em);
  height: 10em;
  width: 25em;
  min-width: 20em;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px ridge darkgrey;
  border-radius: 0.5em;
  background-color: white;
}

#banner #cookieMessage button {
  align-self: flex-end;
  min-width: 5em;
  width: 5em;
}

@media (max-width: 910px) {
  #banner #bannerTop h1 {
    font-size: 1.45em;
  }
  #banner #bannerTop ul {
    font-size: 0.8em;
  }
}

@media (max-width: 760px) {
  #banner #bannerTop h1 {
    margin: 0 0 0 90px;
    font-size: 1.15em;
  }
  #banner #bannerTop ul {
    font-size: 0.65em;
    margin-bottom: 1em;
  }
  #banner #bannerBottom {
    margin: 0 0 0 75px;
  }
}

@media (max-width: 630px) {
  #banner {
    height: 11.5vh;
    margin-bottom: 1em;
  }
  #banner #bannerTop h1 {
    font-size: 1.15em;
    letter-spacing: 0em;
  }
  #banner #bannerTop #bannerTopRight {
    flex-direction: column-reverse;
  }
  #banner #bannerTop #bannerTopRight #logMenu {
    align-self: flex-end;
  }
  #banner #bannerTop #bannerTopRight #navMenu ul {
    margin-left: 0;
    flex-flow: row wrap;
    font-size: 0.65em;
  }
  #banner #bannerTop #bannerTopRight #navMenu ul li {
    margin-top: 0;
    margin-bottom: 0;
  }
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#homeContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#homeContainer #infoMainContainer {
  margin-top: 3em;
  height: 55vh;
  min-height: 600px;
  width: 20vw;
  min-width: 330px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

#homeContainer #infoMainContainer #infoCarroussel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: center center -600px;
          transform-origin: center center -600px;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer {
  height: 100%;
  width: 100%;
  border: 3px ridge darkgrey;
  position: absolute;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle {
  height: 10%;
  background-color: #B04541;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle h3 {
  margin: 0;
  margin-bottom: 0.5vh;
  font-family: "Oswald", sans-serif;
  font-size: 2em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle .infoIcontainer {
  height: 3.5em;
  width: 3.5em;
  position: absolute;
  top: -1.2em;
  left: -1.2em;
  border: 3px ridge darkgrey;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #B04541;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle .infoIcontainer #qualifIcon {
  height: 2.75em;
  width: 2.75em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle .infoIcontainer #skillIcon {
  height: 2.25em;
  width: 2.25em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle .infoIcontainer #presentationIcon {
  height: 2.25em;
  width: 2.25em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent {
  height: 89.4%;
  padding: 1% 0 0 0.5em;
  background-color: white;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent h4 {
  margin: 0;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent .infoItemList {
  margin: 0.1em 0 0.3em 0;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent .infoItemList .itemElement {
  list-style: none;
  margin-top: 0.35em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent .infoItemList .itemElement .infoToolTipIcon {
  margin: 0 0.3em;
  color: darkgrey;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent #presentationText {
  padding: 0.4em;
  font-size: 1.15em;
}

#homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent #presentationText .presentationParagraph {
  margin-bottom: 0.3em;
}

#homeContainer #infoMainContainer #infoCarroussel #qualifInfo {
  -webkit-transform: rotateY(-120deg) translateZ(600px);
          transform: rotateY(-120deg) translateZ(600px);
}

#homeContainer #infoMainContainer #infoCarroussel #skillInfo {
  -webkit-transform: translateZ(600px);
          transform: translateZ(600px);
}

#homeContainer #infoMainContainer #infoCarroussel #presentationInfo {
  -webkit-transform: rotateY(120deg) translateZ(600px);
          transform: rotateY(120deg) translateZ(600px);
}

#homeContainer #infoToolTip {
  position: absolute;
  border: 3px ridge darkgrey;
  background-color: white;
  padding: 0.5em;
  visibility: hidden;
}

#homeContainer #infoToolTip #infoToolTipTitle {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

@media (max-width: 760px) {
  #homeContainer {
    margin-top: 0.5em;
  }
  #homeContainer #infoMainContainer {
    margin-top: 1em;
    min-height: 550px;
  }
  #homeContainer #infoMainContainer #infoCarroussel {
    -webkit-transform-origin: center center -95.3px;
            transform-origin: center center -95.3px;
  }
  #homeContainer #infoMainContainer #infoCarroussel #qualifInfo {
    -webkit-transform: rotateY(-120deg) translateZ(95.3px);
            transform: rotateY(-120deg) translateZ(95.3px);
  }
  #homeContainer #infoMainContainer #infoCarroussel #skillInfo {
    -webkit-transform: translateZ(95.3px);
            transform: translateZ(95.3px);
  }
  #homeContainer #infoMainContainer #infoCarroussel #presentationInfo {
    -webkit-transform: rotateY(120deg) translateZ(95.3px);
            transform: rotateY(120deg) translateZ(95.3px);
  }
  #homeContainer #infoMainContainer #infoCarroussel .infoContainer .infotitle h3 {
    font-size: 1.8em;
  }
  #homeContainer #infoMainContainer #infoCarroussel .infoContainer .infoContent {
    font-size: 0.9em;
  }
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#skillContainer {
  margin-left: 5em;
}

.skillList {
  list-style: none;
  padding: 0;
}

.skillElement {
  margin: 0;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
.expSociety {
  font-weight: bold;
  font-style: italic;
}

.jobDesc {
  margin-left: 3.5em;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#makingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

#makingsContainer #makingsList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;
  height: 50vh;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

#makingsContainer #makingsList .makingItem {
  margin: 1em 0em;
  padding: 0.3em;
  border: 0.3em ridge #EEEEEE;
  border-radius: 0.35em;
  height: 45vh;
  width: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

#makingsContainer #makingsList .makingItem img {
  width: 100%;
  border: 1px solid black;
}

#makingsContainer #makingsList .makingItem .makingItemInfos {
  width: 100%;
  margin-top: 0.75em;
  display: flex;
  flex-direction: column;
}

#makingsContainer #makingsList .makingItem .makingItemInfos .makingItemTitle {
  font-weight: bold;
}

#makingsContainer #makingsList .makingItem .makingItemInfos .makingLink {
  align-self: flex-end;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactContainer #contactForm {
  display: flex;
  flex-direction: column;
}

#contactContainer #contactForm #contactInput_message {
  width: 100%;
  height: 15em;
  margin-bottom: 1em;
  padding: 0.3em;
  resize: none;
  border-radius: 0.5em;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loginContainer #loginTopRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#loginContainer #stayLoggedContainer {
  display: flex;
  align-items: flex-end;
}

#loginContainer .forgottenPwd {
  font-size: 0.75em;
}

#loginContainer #registerLink {
  font-size: 0.9em;
  margin-top: 0.3em;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#registrationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#registrationContainer #registrationForm {
  display: flex;
  flex-direction: column;
}

#registrationContainer #registrationForm .formContainer #newsLetterSubscritpion {
  display: flex;
  align-items: center;
}

#registrationContainer #registrationForm .formContainer #newsLetterSubscritpion input {
  margin: 0 1em;
  width: auto;
}

#registrationContainer #subscritpionCondirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#registrationContainer #subscritpionCondirmation h2 {
  font-size: 2.5em;
  margin: 0;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#adminContainer #addMakingContainer {
  margin: 0 1em;
}

#adminContainer #addMakingContainer #addMakingForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5em 0;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer {
  width: 20em;
  margin: 0 0.5em;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPicPreview {
  height: 12.5em;
  width: 20em;
  margin: 0.5em 0;
  border: 3px ridge #707070;
  background-color: #DDDDDD;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPic {
  visibility: hidden;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPicLabel {
  padding: 0.25em 1em;
}

#adminContainer #addMakingContainer #addMakingForm #makingInfoContainer {
  width: 20em;
  margin: 0 0.5em;
}

#adminContainer #addMakingContainer #addMakingForm #makingInfoContainer #makingInput-info {
  height: 4.5em;
  width: 24em;
  resize: none;
  border: none;
  border-bottom: 1px solid black;
}

/* Colors */
/* Fonts */
#adminContainer #addMakingContainer {
  margin: 0 1em;
}

#adminContainer #addMakingContainer #addMakingForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5em 0;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer {
  width: 20em;
  margin: 0 0.5em;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPicPreview {
  height: 12.5em;
  width: 20em;
  margin: 0.5em 0;
  border: 3px ridge #707070;
  background-color: #DDDDDD;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPic {
  visibility: hidden;
}

#adminContainer #addMakingContainer #addMakingForm #makingPicContainer #makingPicLabel {
  padding: 0.25em 1em;
}

#adminContainer #addMakingContainer #addMakingForm #makingInfoContainer {
  width: 20em;
  margin: 0 0.5em;
}

#adminContainer #addMakingContainer #addMakingForm #makingInfoContainer #makingInput-info {
  height: 4.5em;
  width: 24em;
  resize: none;
  border: none;
  border-bottom: 1px solid black;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#validationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#forgottenPwdContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#resetPwdContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#wipContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#wipMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 3em;
}

#wipMessage img {
  width: 200px;
  height: 200px;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#testContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#testContainer #container3d {
  height: 200px;
  width: 200px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

#testContainer #container3d #cube {
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

#testContainer #container3d #cube .face {
  position: absolute;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: translateZ(-57.7px);
          transform: translateZ(-57.7px);
}

#testContainer #container3d #cube .f1 {
  background-color: #FF000080;
  -webkit-transform: translateZ(57.7px);
          transform: translateZ(57.7px);
}

#testContainer #container3d #cube .f2 {
  background-color: #FFFF0080;
  -webkit-transform: rotateY(-120deg) translateZ(57.7px);
          transform: rotateY(-120deg) translateZ(57.7px);
}

#testContainer #container3d #cube .f3 {
  background-color: #0000FF80;
  -webkit-transform: rotateY(120deg) translateZ(57.7px);
          transform: rotateY(120deg) translateZ(57.7px);
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  from {
    -webkit-transform: rotateX(360deg) rotateY(360deg);
            transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  from {
    -webkit-transform: rotateX(360deg) rotateY(360deg);
            transform: rotateX(360deg) rotateY(360deg);
  }
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
/*Styles composant Body*/
#contentContainer {
  box-sizing: content-box;
  padding: 1em 0;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
#footer {
  height: 5em;
  width: 100%;
  border-top: 1px solid darkgrey;
  padding: 1em;
  color: #888888;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
}

#footer #footerLinkTitle {
  flex: 1 1;
}

#footer #footerLinkContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
  flex: 3 1;
}

#footer #footerLinkContainer a {
  text-decoration: none;
  color: #888888;
  font-size: 0.8em;
}

#footer #footerBottomLine {
  flex: 1 1;
  -ms-grid-row-align: center;
  align-self: center;
}
/*# sourceMappingURL=style.css.map */
/* Colors */
/* Fonts */
/* Principal */
#root {
  min-height: 99vh;
  font-family: "KoHo", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Classes Globales */
.link {
  cursor: pointer;
  text-decoration: none;
  color: black;
  transition: color 0.25s;
}

.link:hover {
  text-decoration: underline;
  color: #B04541;
  transition: color 0.25s;
}

/* Formulaires */
.formContainer {
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.labelRow {
  margin-bottom: 0.5em;
  width: 20vw;
  min-width: 20em;
  max-width: 30em;
}

.formContainer label {
  font-weight: bold;
}

.formContainer input {
  width: 100%;
  height: 2.2em;
  margin-bottom: 1.5em;
  border: none;
  border-bottom: 1px solid black;
}

.formButton {
  margin: 1.5em 0 0.5em 0;
  cursor: pointer;
  width: 20vw;
  min-width: 20em;
  max-width: 30em;
  height: 2.5em;
  border: none;
  border-radius: 2em;
  background-color: darkgrey;
  transition: 0.25s;
  font-weight: bold;
}

.formButton:hover {
  background-color: #B04541;
  color: white;
}

.requiredField {
  color: red;
}

.formInputError {
  background-color: #FFBBBB;
  border-color: 1px solid red;
  border-radius: 0.3em 0.3em 0 0;
  color: red;
}

/* Messages d'erreurs*/
.error {
  margin: 1em;
  color: red;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error .errorLink {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

/* Contenu */
.pageTitle {
  font-size: 1.6em;
  margin: 0.5em 0 2em 0;
  text-align: center;
}

.contentArticle {
  margin: 3.5em 5em;
  padding-left: 2em;
}

.contentTitle {
  height: 100%;
  display: flex;
  margin: 0.2em 0 0.75em 0em;
  padding: 0.5em 1.5em;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px double #B04541;
}

.carrousselCommand {
  margin: 0 0.5em;
  height: 1.25em;
  width: 1.25em;
  border-style: none;
  border-radius: 100%;
  background-color: #B04541;
  font-size: 2.5em;
  color: white;
  cursor: pointer;
}

pre {
  font-family: "KoHo", sans-serif;
  font-size: 1.1em;
}

@media (max-width: 760px) {
  .carrousselCommand {
    margin: 0 0.5em;
    height: 1.15em;
    width: 1.15em;
    font-size: 1.75em;
  }
}
/*# sourceMappingURL=App.css.map */
